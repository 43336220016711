<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-alarm-multiple"
      title="Aktuelle Ligen"
      color="primary"
    >
      <v-data-table
        :headers="[
          { text: 'Name', value: 'name', sortable: true },
          { text: 'Von', value: 'startDate', sortable: true },
          { text: 'Bis', value: 'endDate', sortable: true }
        ]"
        :items="events"
        sort-by="startDate"
        sort-desc
        mobile-breakpoint="0"
        @click:row="open"
      >
        <template #item.name="{item}">
          <span style="font-weight:bold;">{{ item.name }}</span>
          <span
            v-if="item.untertitel"
            style="font-style:italic;"
          >
            ({{ item.untertitel }})
          </span>
        </template>
        <template #item.startDate="{item}">
          {{ item.startDate | date }}
        </template>
        <template #item.endDate="{item}">
          {{ item.endDate | date }}
        </template>
      </v-data-table>
      <v-switch
        v-model="events_all"
        label="alte Events anzeigen"
      />
    </base-material-card>

    <v-row>
      <template
        v-for="e in events"
      >
        <template v-if="e.type === 'stb_m_2021'">
          <v-col md="6" cols="12" :key="`start-${e._id}`">
            <stb-m2021-start :id="e._id" />
          </v-col>
          <v-col md="6" cols="12" :key="`news-${e._id}`">
            <stb-m2021-news :id="e._id" />
          </v-col>
        </template>
        <template v-if="e.type === 'stb_w_2023'">
          <v-col md="6" cols="12" :key="`start-${e._id}`">
            <stb-w2023-start :id="e._id" />
          </v-col>
          <v-col md="6" cols="12" :key="`news-${e._id}`">
            <stb-w2023-news :id="e._id" />
          </v-col>
        </template>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { apolloDefault } from '@/plugins/graphql'
import { mapGetters } from 'vuex'

const query = `
  _id
  ... on StbM2021 {
    name startDate endDate type
  }
  ... on StbW2023 {
    name startDate endDate type
  }
  ... on StbLtf2024 {
    name startDate endDate type
  }
`

export default {
  name: 'start',

  components: {
    StbM2021Start: () => import('./stb_m_2021/Start'),
    StbM2021News: () => import('./stb_m_2021/News'),
    StbW2023Start: () => import('./stb_w_2023/Start'),
    StbW2023News: () => import('./stb_w_2023/News')
  },

  data: () => ({
    events_all: false,
    EventFind: []
  }),

  computed: {
    ...mapGetters(['isVisible']),
    events () {
      const now = new Date()
      now.setMonth(now.getMonth() - 3)
      return this.EventFind.filter(e => this.isVisible(e.type) && (this.events_all || e.endDate >= now.toISOString())).sort((a, b) => a.startDate > b.startDate ? -1 : 1)
    }
  },

  methods: {
    open (item) {
      this.$router.push(`/e/${item._id}/start`)
    }
  },

  apollo: apolloDefault('Event', query, '(parent: null)')
}
</script>

<style scoped>
img {
  max-width: calc(100% - 48px);
  width: 400px;
  height: auto;
  float: right;
  margin: 0px 24px 24px 24px;
}

h2 {
  line-height: 180%;
  margin-bottom: 16px;
  letter-spacing: 0.2px;
}

h3 {
  line-height: 180%;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}
</style>
